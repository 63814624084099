<template>
    <div class="content-body">
        <div class="header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item :to="{ path: '/practice/index' }">实操题</el-breadcrumb-item>
                <el-breadcrumb-item>添加关键词</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">添加关键词</div>
            </div>
            <div class="step-item" :class="{current: [2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content create-table" v-if="stepActive===1">
            <div class="create-content-top">
                <div class="create-content-left">
                    <el-button type="primary" plain @click="importKeyword" style="margin-right: 10px">导入关键词</el-button>
                    <input type="file" ref="excelInput" accept="excel/*" title="" @change="changeExcel($event)" style="display: none;">
                    <el-button type="primary" plain @click="exportKeyword">导出关键词</el-button>
                    <el-button type="primary" plain @click="downloadTemplate">下载模板</el-button>
                </div>
                <div class="create-content-right">
                    <label class="label-name">关键词名称</label>
                    <el-input type="text" v-model="s_name" placeholder="请输入关键词名称" class="name-input"></el-input>
                </div>
            </div>
            <el-table :data="hotKeywordsTable" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%"  size="medium"
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                      :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                      class="customTable" :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                <el-table-column prop="name" align="center" label="关键词">
                    <template slot-scope="scope">
                        <el-input
                                v-model="scope.row.name"
                                style="width: 100%"
                                @blur="saveItem(scope.row.name)">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="search_index" align="center" label="搜索指数">
                    <template slot-scope="scope">
                        <el-input-number
                                v-model="scope.row.search_index"
                                @change="inputVal"
                                :min="1"
                                :max="99999999999"
                                :controls="false"
                                :precision="0"
                                style="width: 100%"
                                @blur="saveItem(scope.row.search_index)">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="search_click_index" align="center" label="搜索点击指数">
                    <template slot-scope="scope">
                        <el-input-number
                                v-model="scope.row.search_click_index"
                                @change="inputVal"
                                :min="1"
                                :max="99999999999"
                                :controls="false"
                                :precision="0"
                                style="width: 100%"
                                @blur="saveItem(scope.row.search_click_index)">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="click_rate" align="center" label="点击率（%）">
                    <template slot-scope="scope">
                        <el-input-number
                                v-model="scope.row.click_rate"
                                @change="inputVals"
                                style="width: 100%"
                                @blur="saveItem(scope.row.click_rate)"
                                :min="0.01"
                                :max="100"
                                :controls="false"
                                :precision="2">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="turnover_index" align="center" label="成交金额指数">
                    <template slot-scope="scope">
                        <el-input-number
                                v-model="scope.row.turnover_index"
                                @change="inputVal"
                                :min="1"
                                :max="99999999999"
                                :controls="false"
                                :precision="0"
                                style="width: 100%"
                                @blur="saveItem(scope.row.turnover_index)">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="turnover_conversion_rate" align="center" label="成交转化率（%）">
                    <template slot-scope="scope">
                        <el-input-number
                                v-model="scope.row.turnover_conversion_rate"
                                @change="inputVals"
                                style="width: 100%"
                                @blur="saveItem(scope.row.turnover_conversion_rate)"
                                :min="0.01"
                                :max="100"
                                :controls="false"
                                :precision="2">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120px">
                    <template slot-scope="scope">
                        <el-link :underline="false" type="danger" @click="delBtn(scope.$index, scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add-footer">
                <el-button type="primary" plain @click="addKeywords">添加新关键词</el-button>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="success-content">
                <img src="../../../assets/images/admin/success.png" alt="">
                <p class="text">创建成功</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="keywordsPrevBtn"  v-if="stepActive === 1">上一步</el-button>
            <el-button @click="keywordsPrevBtn" type="primary" v-if="stepActive === 2">返回列表</el-button>
            <el-button type="primary"  @click="keywordsNextBtn" v-if="stepActive === 1">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import {adminKeyWordSaveKeyWord, adminKeyWordGetKeyWord, adminKeyWordImportKeyword, adminKeyWordGetExportKeyword} from '@/utils/apis'
    export default {
        name: "HotKeywordsCreate",
        data() {
            return {
                //步骤
                stepActive: 1,
                //关键词列表
                hotKeywordsTable: [],
                //关键词名称
                s_name: '',
                //关键词id
                keywordId: Number(this.$route.query.id) || 0,
                //导入关键词
                keyword_excel: '',
                excelFile: '',
                excelFileName: '',
            }
        },
        mounted() {
            this.getFindHotKeyword();
        },
        methods: {
            //编辑关键词
            getFindHotKeyword() {
                if (this.keywordId) {
                    let param = {
                        id: this.keywordId
                    }
                    adminKeyWordGetKeyWord(param).then((res) => {
                        this.s_name = res.data.name;
                        this.hotKeywordsTable = res.data.content;
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            },
            //上一步
            keywordsPrevBtn() {
                this.$router.push({
                    path: '/practice/hotKeywords'
                })
            },
            //下一步(保存)
            keywordsNextBtn() {
                // console.log(this.hotKeywordsTable.length);
                if(this.s_name){
                    if (this.hotKeywordsTable.length > 0) {
                    let formData = new FormData();
                    formData.append('name', this.s_name);
                    formData.append('content', JSON.stringify(this.hotKeywordsTable));
                    //验证关键词名称
                    let dialog_flag = 0;
                    this.hotKeywordsTable.forEach(item => {
                        if (!item.name) {
                            dialog_flag++;
                            if (dialog_flag === 1) {
                                return this.$message.warning('请输入关键词');
                            }
                            return;
                        }
                    });
                    if (dialog_flag) {
                        return;
                    }
                    if (this.keywordId) {
                        formData.append('id', this.keywordId);
                    }
                    adminKeyWordSaveKeyWord(formData).then((res) => {
                        this.stepActive = 2;
                        this.$message.success(res.msg);
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    this.$message.warning('请选择要添加的关键词！');
                }
                }else{
                    this.$message.warning('关键词不能为空！');
                }
               
            },
            //添加关键词
            addKeywords() {
                if (this.hotKeywordsTable === undefined) {
                    this.hotKeywordsTable = new Array();
                }
                let newAddKeyword = {};
                newAddKeyword.click_rate = null;
                newAddKeyword.name = '';
                newAddKeyword.search_index = null;
                newAddKeyword.search_click_index = null;
                newAddKeyword.turnover_index = null;
                newAddKeyword.turnover_conversion_rate = null;
                this.hotKeywordsTable.push(newAddKeyword);
            },
            //失焦事件（保存手动添加关键词）
            saveItem(val) {
                if (val === '' || val === null) {
                    this.$message.warning('请填写相关数据');
                    return false;
                }
            },
            //输入的搜索量必须大于0
            inputVal(val) {
                if (Number(val) === 0) {
                    this.$message.warning('输入的搜索量必须大于0！');
                    return false;
                }
            },
            //输入的点击率，转化率必须大于0且小于等于100
            inputVals(val) {
                if (val === Number(val)) {
                    if (Number(100 <= val && val >= 0)) {
                        this.$message.warning('输入的点击率，转化率必须大于0且小于等于100！');
                        return false;
                    }
                } else {
                    this.$message.warning('输入的点击率，转化率必须是数字！');
                }

            },
            //删除
            delBtn(index, row) {
                this.$confirm('是否删除该关键词，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.hotKeywordsTable.splice(index, 1);
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //导入关键词
            importKeyword() {
                this.$refs.excelInput.value = null;
                this.$refs.excelInput.click();
            },
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 200) {
                    this.$message.warning('文件超过200M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.excelFile = fileList;
                    this.excelFileName = fileList.name;
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.keyword_excel = "data:excel/*," + url;
                    }
                }
                let formData = new FormData();
                formData.append('excel', this.excelFile);
                adminKeyWordImportKeyword(formData).then((res) => {
                    this.hotKeywordsTable = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取要导出的关键词并下载
            exportKeyword() {
                let formData = new FormData();
                let keywordArr = [];
                keywordArr = this.hotKeywordsTable;
                formData.append('content', JSON.stringify(keywordArr));
                adminKeyWordGetExportKeyword(formData).then((res) => {
                    this.$message.success('导出成功，正在下载');
                    window.location.href = localStorage.getItem('hosturl') + 'exportKeyword/' + this.$route.query.id;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //下载模板
            downloadTemplate() {
                window.location.href = localStorage.getItem('hosturl') + 'uploads/admin_keywords/excel/keywords_template.xls';
                
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .header {
            padding: 0 20px;
        }
        .steps-wrapper {
            padding: 20px;
        }
        .create-content{
            flex: 1;
            padding: 0 20px;
            .create-content-top{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .create-content-left{
                    /*::v-deep.el-button--primary.is-plain {*/
                    /*    color: #1CB064;*/
                    /*    background: transparent;*/
                    /*    border-color: #a4dfc1;*/
                    /*}*/
                }
                .create-content-right{

                    display: flex;
                    align-items: center;
                    .label-name{
                        min-width: 100px;
                    }
                }
                .name-input {
                    width: 325px;
                    ::v-deep .el-input__inner {
                        border-radius: 0;
                    }
                }
            }
            .add-footer{
                margin-top: 20px;
            }
            .success-content{
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 140px;
                .text{
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                }
            }
        }
        .create-table {
            height: 1%;
            display: flex;
            flex-direction: column;
        }
        .bottom-btn{
            text-align: center;
            padding: 20px;
        }
    }
</style>